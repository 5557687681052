.dashboard-card{
    background-color: #fff;
    border-radius: 4px;
    padding: 24px 24px;
    box-shadow: 0 2px 10px 0 rgba(133,157,177,0.10);

    &.has-inner{
        padding: 0;
    }

    .dashboard-card-header{
        padding: 24px;
    }
    .dashboard-card-body{
        position: relative;
    }
    .dashboard-card-footer{
        padding: 24px 12px;
    }
}

.cards-group{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .card-sm{
        flex-grow: 1;
        flex-basis: 0;
        margin: 0 12px 24px 12px;
    }

    &.three-col{

        .card-sm{
            width: 31%;
            flex-grow: inherit;
            flex-basis: auto;
            margin: 0 0 24px 0;
        }
    }
}

.card-sm{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.card-title-sm{
    color: $label-muted;
    font-size: .875rem;
    font-weight: 400;
    margin-bottom: 6px;
    position: relative;
}

.card-stat-change{
    color: #04c180;
    font-size: .75rem;
    font-weight: 600;
}

.card-value{
    font-size: 1.5rem;
    font-weight: 700;
}

.card-list{
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;

    li{
        padding: 12px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
}

.card-row{
    border-bottom: 1px solid #e3eef6;

    &:first-child{
        border-top: 1px solid #e3eef6;
    }
}

.card-loader,
.card-loader-sm{
    width: 44px;
    height: 44px;
    padding: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 4px;
    background-color: #222;
    color: #fff;
    text-align: center;
    font-size: 22px;
    z-index: 1000;
}

.card-loader-sm{
    width: 37px;
    height: 37px;
    padding: 5px;
    font-size: 18px;
}

.card-card-image{
    max-width: 60px;
    height: auto;
}
