.dashboard-app{
    display: flex;
    flex-direction: row;
}

.half-cards{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .dashboard-card{
        width: 48%;
        margin-bottom: 24px;
    }
}

.flatpickr{
    position: relative;

    i{
        pointer-events: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 12px;
    }
}

.form-control[readonly] {
    background-color: #fff;
    opacity: 1;
}

.table thead tr th{
    border-bottom: none;
}

.table-tabs{
    display: flex;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    border-bottom: 1px solid #ebf2f9;

    li{

        a{
            display: block;
            padding: 10px 24px;
            color: #6f95af;
            text-transform: uppercase;
            font-weight: 600;
            font-size: .875rem;
            border-bottom: 3px solid #fff;
            transition: background-color .25s ease, border-color .25s ease, color .25s ease;

            &:hover{
                background-color: #f3f3f3;
            }

            &.active{
                border-bottom: 3px solid #199efb;
                color: #00a4d1;
            }
        }
    }
}

.input-has-icon{
    position: relative;

    input{
        padding-right: 20px;

        ::-webkit-input-placeholder { color: $input-placeholder-color; }
        :-moz-placeholder { color: $input-placeholder-color; }
        ::-moz-placeholder { color: $input-placeholder-color; }
        :-ms-input-placeholder { color: $input-placeholder-color; }
    }

    i{
        position: absolute;
        right: 12px;
        top: 50%;
        color: $input-placeholder-color;
        transform: translateY(-50%);
    }
}

.table-tabs-content{
    position: relative;
}

.table-tab-content{
    display: none;
    padding: 24px;

    &.active{
        display: block;
    }
}

.company-name{
    font-size: .875rem;
    color: #7fa0b6;
}

.pagination{
    .page-item{
        .page-link{
            width: 40px;
            height: 40px;
            border-radius: 50% !important;
            text-align: center;
            border: none !important;
            color: #222;
            font-weight: 600;
            line-height: 1.5;
            margin: 0 4px;
        }

        &.active{
            .page-link{
                color: #fff;
            }
        }
    }
}

.btn{
    &.btn-light{
        background-color: transparent;
        border-color: transparent;

        &:hover{
            background-color: #f8f9fa;
        }
    }

    &.btn-export{
        background-color: $export-btn-bgcolor;
        border-color: $export-btn-bgcolor;
        color: #222;
        font-weight: 600;

        &:hover{
            background-color: #f8f9fa;
        }
    }
}


.table{
    th{
        color: $table-header-font-color;
        font-weight: 600;
    }
}

.chart-title{
    position: absolute;
    top: 20px;
    left: 24px;
    z-index: 900;
}
