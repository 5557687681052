// Fonts
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&display=swap');
@import 'components/fontawesome-pro/scss/fontawesome';
@import 'components/fontawesome-pro/scss/brands';
@import 'components/fontawesome-pro/scss/regular';
@import 'components/fontawesome-pro/scss/light';
@import 'components/fontawesome-pro/scss/solid';
@import '~flatpickr/dist/flatpickr.css';

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import 'base';

// Globals
@import 'globals/helpers';
@import 'globals/pages';

// Sections
@import 'sections/header';
@import 'sections/footer';

// Pages
@import 'pages/cards';
@import 'pages/affiliate-activity';
@import 'pages/sales-reports';
@import 'pages/settings';
@import 'pages/account';
@import 'pages/faqs';

// Components
@import 'components/forms';
@import 'components/cards';
@import 'components/breadcrumbs';
@import 'components/messages';
@import 'components/modals';

// DASHBOARD - TODO will prob move to another compiled CSS file
@import 'globals/dashboard';
@import 'sections/dashboard-header';
@import 'sections/dashboard-nav';
@import 'sections/dashboard-content';

@import 'globals/responsive';
