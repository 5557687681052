.card-availability{
    display: inline-block;
    position: relative;
    font-size: .875rem;
    font-weight: 700;
    color: #f42a3d;

    &:before{
        display: inline-block;
        vertical-align: middle;
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        margin-right: 6px;
        background-color: #f42a3d;
        margin-top: -1px;
    }

    &.avail{
        color: #04c180;

        &:before{
            background-color: #04c180;
        }
    }
}

.sortable-column{
    cursor: pointer;
}

.table-card-img{
    max-width: 100%;
    height: auto;
}

.table-card-name{
    font-size: .875rem;
    font-weight: 600;
    color: #223345;
}

.table-card-bullets{
    ul{
        diplay: block;
        padding: 0;
        margin: 0;
        font-size: .75rem;
        color: #555;
        list-style: none;

        li{
            font-weight: 400;
            font-size: .75rem;
            color: #555;
        }
    }
}

.table-showing{
    display: flex;
    width: 40%;
    align-items: center;

    select{
        margin-right: 24px;
        width: 100px;
    }
}

.table-paging{
    display: none;
    justify-content: space-between;
    padding: 12px 0;
    align-items: center;

    &.show{
        display: flex;
    }
}

.card-img{
    max-width: 100%;
    height: auto;
    margin-bottom: 32px;
}
